import { FunctionComponent } from "react";
import './style/index.scss';
import { useState, useEffect } from "react";
import {sendRequest} from './../../services/Authorization';
import { Spinner } from "@sbpay/spinner";
import { MsConfig } from "../../MsConfig/MsConfig";
import { useParams } from "react-router-dom";
interface ValidationPageProps {
    
}
 
const ValidationPage: FunctionComponent<ValidationPageProps> = () => {
    const [isEnabled] = useState(false);
    const params = useParams();
    useEffect(() => {
        const send = async () => {
            const response = await sendRequest(isEnabled,params.data);
            window.location.href = MsConfig.config.frontPhp.url+'?token='+response.payload;
        }
        send();
    }, [])
    return (
        <div className="validation-page">
            <div className="card">
                <Spinner />
                <div className="card-title">¡Espera un poco!</div>
                <div className="card-text">Estamos evaluando tus datos crediticios. Te recomendamos no cerrar ni actualizar tu navegador.</div>

                <div className="card-secondary-text">Esto puede tardar unos segundos...</div>
            </div>
        </div>
    );
}
 
export default ValidationPage;