import { FunctionComponent } from "react";

interface NotFoundPageProps {
    
}
 
const NotFoundPage: FunctionComponent<NotFoundPageProps> = () => {
    return ( 
        <div>
            Error 404 ! Page not found
        </div>
     );
}
 
export default NotFoundPage;