import ReactDOM from 'react-dom/client';
import { BrowserRouter,Routes, Route } from 'react-router-dom';

import AuthorizationPage from './pages/Autorization';
import ValidationPage from './pages/Validation';
import NotFoundPage from './pages/NotFound';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
  <Routes>
    <Route path='/:data' element={<AuthorizationPage/>}></Route>
    <Route path='/validation/:data' element={<ValidationPage/>}></Route>
    <Route path="*" element={<NotFoundPage/>}></Route>
  </Routes>
  </BrowserRouter>
);