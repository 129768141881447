import { FunctionComponent } from "react";
import './style/index.scss';
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

interface AuthorizationPageProps {
    
}
 
const AuthorizationPage: FunctionComponent<AuthorizationPageProps> = () => {
    const [isEnabled, setEnabled] = useState(false);
    const handleClick = () => {setEnabled(!isEnabled);}
    const params = useParams();

    return (
        <div className="authorization-page">
            <div className="title">
                <h4>Evaluación crediticia</h4>
                <p>Necesitamos <span>actualizar tus datos con Previred</span><br/>para determinar si tienes un nuevo cupo en tu tarjeta sbpay VISA.</p>
            </div>
            <div className="card">
                <div className="card-title">Autorización para<br/> consultar en Previred</div>
                <div className="card-text">Por este acto, autorizo a mi AFP, a entregar por intermedio de Previred, mis 12 o 24 últimos períodos de cotizaciones previsionales a sbpay, con el fin de ser consideradas como antecedente a esta solicitud comercial, dando así cumplimiento al artículo 4° de la ley N° 19.628 sobre Protección de la Vida Privada.</div>
                <div className="auth-terms">
                    <input type="checkbox" className="auth-checkbox" onClick={handleClick} ></input>
                    <label ><span>Autorizo a sbpay</span> para consultar mis datos en Previred.</label>
                </div>
                <div className="disclaimer">Al autorizar podemos entregar una mejor posibilidad de cupo a tu sbpay VISA.</div>
                <div className="button-container">
                    
                    <Link to={'/validation/' + params.data}>
                        <button className="primary" disabled={!isEnabled?? "disabled"}>Autorizar</button>
                    </Link>
                    <button className="secondary">cancelar</button>
                </div>
            </div>
        </div>
    );
}
 
export default AuthorizationPage;