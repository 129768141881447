import  { MsConfig } from '../MsConfig/MsConfig';

const headersServices = {
    headers: {
        'Content-Type': 'application/json',
        'app_id': MsConfig.config.app_id,
        'app_key': MsConfig.config.app_key
    },
};

export { headersServices };