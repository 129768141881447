import  { MsConfig } from '../../MsConfig/MsConfig';
import { headersServices } from '../../config/headersServices';

export const  sendRequest: any = async (enabled: boolean,data:string)=>{
    const url = MsConfig.config.orchestratorSbPay.url;
    const options ={
        method : 'POST',
        headers: headersServices.headers,
        body: JSON.stringify({ 'data': data }),
    }
    const response = await fetch(url, options);
    return response.json();
}